<template>
    <section class="product-features">
        <div class="product-feature">
            <div class="product-feature-icon">
                <img src="@/assets/icons/book-open.svg" alt="">
            </div>
            <div class="product-feature-text">
                <p>
                    <span>Personalized Learning. </span>Transforms learning by customizing lessons,exercises to match the
                    child’s
                    unique learning style, ensuring every lesson feels just right for them.
                </p>
            </div>
        </div>
        <div class="product-feature">
            <div class="product-feature-icon">
                <img src="@/assets/icons/brain.svg" alt="">
            </div>
            <div class="product-feature-text">
                <p>
                    <span>Interactive Content. </span> Elevates the child’s learning with immersive, engaging activities
                    that make each lesson an exciting, wholesome experience.
                </p>
            </div>
        </div>
        <div class="product-feature">
            <div class="product-feature-icon">
                <img src="@/assets/icons/look.svg" alt="">

            </div>
            <div class="product-feature-text">
                <p>
                    <span>Insights. </span>
                    Provides real-time updates on the child’s progress, so you can easily track their achievements and growth.
                </p>
            </div>
        </div>
    </section>
</template>