<template>
    <nav>
        <div class="nav-logo">
            <img src="@/assets/logo.png" alt="Logo"/>
        </div>

        <div class="nav-title">
            <div class="row default-gap">
                <a href="#hero-page">
                    Home

                </a>
                <a href="#home-page-packages">

                    Products
                </a>
                
                <a href="#contact-page">

                    Support
                </a>
               
            </div>
        </div>

        <div class="nav-title">
            <div class="row ">
                <a href="#contact-page">
                    Contact
                </a>
                <button class="primary-btn">
                    Get Started
                </button>
            </div>
        </div>
    </nav>
</template>